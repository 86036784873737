<template>
  <div>
    <el-card shadow="never" style="min-height: 800px;height: auto">
      <el-row style="margin-bottom: 22px">
        <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="currentChange(1)" style="width: 300px"></el-input>
        <el-button @click="currentChange(1)" type="primary" :loading="loading" class="query-btn">查询</el-button>
        <el-button type="primary" @click="impDialogVisible = true">导入</el-button>
      </el-row>

      <el-table :data="dataList" border style="width: 100%" stripe>
        <el-table-column align="center" prop="assetSn" label="资产码"></el-table-column>
        <el-table-column align="center" prop="lastTime" label="截止时间"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="deleteData(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="formData.pageNo"></el-pagination>
      </div>
    </el-card>
    <el-dialog title="导入DOA数据" :visible.sync="impDialogVisible" :close-on-click-modal="false" width="800px" :before-close="impHandleClose">
      <div class="remark" style="margin: 0">
        <div>操作指南：</div>
        <div>1. 请先下载
          <el-link type="primary" href="template/doaData.xlsx">Excel模板</el-link>
        </div>
        <div>2. 按模板文件格式填写数据</div>
        <div>3. 确认文件内容无误后，上传文件</div>
        <div>4. 如上传文件内容有误，修改后重新上传即可覆盖上次数据</div>

        <el-upload class="upload-demo"
                   drag
                   :headers="uploadHeaders"
                   :on-success="uploadSuccess"
                   :on-progress="uploadProgress"
                   :data="formData"
                   accept=".xlsx,.xls"
                   :action="uploadUrl">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
          <p slot="tip" v-if="loading">上传中</p>
          <p class="loadingThree" slot="tip" v-if="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </p>
          <p slot="tip" :class="loadSuccess?'success':'error'">{{ success }}</p>
        </el-upload>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="impHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "DoaData",
  data() {
    return {
      formData: {
        assetSn: '',
        pageNo: 1
      },
      dataList: [],
      total: 0,
      pageSize: 0,
      impDialogVisible: false,
      loading: false,
      uploadUrl: '',
      uploadHeaders: {},
      success: '',
      loadSuccess: false,
    }
  },
  created() {
    this.queryList();
    this.uploadUrl = this.$baseUrl + 'doaData/upload';
    this.uploadHeaders = {token: localStorage.getItem('token')};
  },
  methods: {
    queryList() {

      this.loading = true
      this.$axios.post('doaData/queryPage', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.dataList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
        this.loading = false;

      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.formData.pageNo = pageNo;
      this.queryList()
    },
    deleteData(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('doaData/delete', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.queryList();
        })
      })
    },
    impHandleClose() {
      this.queryList()
      this.impDialogVisible = false;
    },
    uploadSuccess: function (response) {
      this.success = response.message;
      if (response.code == 0) {
        this.loadSuccess = true;
      } else {
        this.loadSuccess = false;
      }
    },
    uploadProgress: function () {
      this.loadSuccess = false;
    },
  }
}
</script>

<style scoped>
.query-btn {
  margin-left: 10px;
}

.remark {
  line-height: 30px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  color: darkslategray;
}

.success {
  color: green;
  font-size: 18px;
  padding-right: 10px;
}

.error {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}
</style>